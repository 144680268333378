





































import Vue from "vue";
export default Vue.extend({
  name: "item",
  props: ["text", "value", "textRight", "valueRight"],
  data() {
    return {};
  }
});
