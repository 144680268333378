































































import Vue from "vue";
import Item from "./detail-item.vue";
import { Image, ActionSheet, Dialog, Toast, Field, Button, PullRefresh, CellGroup, Cell } from "vant";
import moment from "moment";
moment.locale("zh-cn");

export default Vue.extend({
  components: {
    Item,
    [Image.name]: Image,
    [ActionSheet.name]: ActionSheet,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field,
    [Button.name]: Button,
    [PullRefresh.name]: PullRefresh,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell
  },
  data() {
    return {
      orderId: this.$route.params.id,
      order: {} as Order,
      isRefreshing: false,
      onViewMode: true,
      showActions: false,
      orderRefundDialog: {
        money: 0,
        mark: "",
        show: false,
        info: ""
      },
      showRefundList: false
    };
  },
  created() {
    this.getData();
  },
  methods: {
    onRefresh() {
      this.isRefreshing = true;
      this.getData().finally(() => {
        this.isRefreshing = false;
      });
    },
    getData() {
      return this.$axios.post("/api/manage/battery/chargeOrder/detail", { orderId: this.orderId }).then(res => {
        const order = res.data.data;
        order.rentTime = this.$secToTime(order.rentTime);
        order.chargeTime = this.$secToTime(order.chargeTime);
        this.order = order;
        this.onViewMode = order.isPreview;
      });
    },
    getPayMethodMsg() {
      if (this.order.payState != 1) return "未支付";
      if (!this.order.chargeMode) return "";
      return this.order.chargeMode == 3 ? "包月" : this.order.payMethod == 5 ? "" : this.order.payMethodMsg;
    },
    roomDoorOpen() {
      const data = {
        cabinetRoomId: this.order.cabinetRoomId,
        orderId: this.orderId
      };
      Dialog.confirm({ message: "确定要发送开仓指令吗?" }).then(() => {
        this.$axios.post("/api/manage/battery/chargeOrder/room/open", data).then(() => {
          Toast.success({
            message: "指令已下发",
            onClose: () => {
              this.getData();
            }
          });
        });
      });
    },
    roomStopCharge() {
      const data = {
        cabinetRoomId: this.order.cabinetRoomId,
        orderId: this.orderId
      };
      Dialog.confirm({ message: "确定要停止充电吗?" }).then(() => {
        this.$axios.post("/api/manage/battery/chargeOrder/room/stop", data).then(() => {
          Toast.success({
            message: "指令已下发",
            onClose: () => {
              this.getData();
            }
          });
        });
      });
    },
    roomStartCharge() {
      Dialog.confirm({ message: "确定要发送充电指令吗?" }).then(() => {
        this.$axios.post("/api/manage/battery/chargeOrder/startCharge", { orderId: this.orderId }).then(() => {
          Toast.success({
            message: "指令已下发",
            onClose: () => {
              this.getData();
            }
          });
        });
      });
    },
    orderEnd() {
      Dialog.confirm({ message: "确定要结束订单吗?" }).then(() => {
        this.$axios.post("/api/manage/battery/chargeOrder/end", { orderId: this.orderId }).then(() => {
          Toast.success("订单已结束");
          this.getData();
        });
      });
    },
    showOrderRefundDialog() {
      const moneyToBeRefund = this.order.money - this.order.refundMoney;
      const moneyAlreadyRefund = this.order.refundMoney;
      if (moneyToBeRefund < 0.01) {
        Toast.fail("所有金额已退款");
        return;
      }
      this.orderRefundDialog.info = "可退款 ¥" + Math.floor(moneyToBeRefund * 100) / 100 + (moneyAlreadyRefund > 0 ? " 元, 已退款 ¥" + moneyAlreadyRefund : "") + " 元";
      this.orderRefundDialog.show = true;
    },
    orderRefund() {
      const moneyToBeRefund = this.order.money - this.order.refundMoney;
      const money = this.orderRefundDialog.money;
      const mark = this.orderRefundDialog.mark;
      if (money < 0.01) {
        Toast.fail("请填写有效的退款金额");
        return;
      }
      if (money > moneyToBeRefund) {
        Toast.fail("超过可退款金额");
        return;
      }
      if (!mark) {
        Toast.fail("请填写退款备注");
        return;
      }
      const data = {
        orderId: this.orderId,
        money: Math.floor(money * 100),
        mark: mark
      };
      Dialog.confirm({ message: "确定要退款吗?" }).then(() => {
        this.$axios.post("/api/manage/battery/chargeOrder/refund", data).then(() => {
          Toast.success("退款成功");
          this.getData();
        });
      });
    },
    toPhone() {
      location.href = "tel://" + this.order.phone;
    },
    toCabinet() {
      this.toUrl("/web/manager/manager_switch_cabinet_information?qrCode=" + this.order.qrCode);
    },
    toRoom() {
      this.toUrl("/web/manager/manager_switch_cabinet_information?cabinetId=" + this.order.cabinetId + "&no=" + this.order.no);
    },
    toDeviceData() {
      const startTime = moment(this.order.createTime, "YYYY年MM月DD日 hh:mm").unix() - 60;
      const endTime = (this.order.endTime ? moment(this.order.endTime, "YYYY年MM月DD日 hh:mm").unix() : moment().unix()) + 60;
      this.$router.push("/device/data?box2Id=" + this.order.box2Id + "&no=" + this.order.no + "&startTime=" + startTime + "&endTime=" + endTime);
    },
    toDataRecord() {
      localStorage.cabinetRoomId = this.order.cabinetRoomId;
      this.toUrl("/web/manager/manager_switch_cabinet_datarecord_room");
    },
    toUrl(url: string) {
      if (this.onViewMode) return;
      this.$toUrl(url);
    }
  }
});

interface Order {
  payMethodMsg: string;
  no: number;
  chargeState: number;
  role: string;
  isPreview: boolean;
  orderId: number;
  cabinetId: number;
  setU: string;
  isConnect: boolean;
  businessName: string;
  orderStatus: number;
  box2Id: number;
  chargeStatus: number;
  roomId: number;
  lockState: number;
  wh: string;
  setI: string;
  qrCode: string;
  payMethod: number;
  payState: number;
  isTimeOut: boolean;
  startTime: string;
  chargeStateMsg: string;
  power: string;
  chargeFee: string;
  temp: string;
  orderStatusMsg: string;
  selectTime: string;
  avatarUrl: string;
  chargeModeMsg: string;
  chargeTime: number;
  i: string;
  refundMoney: number;
  isAllowOrderOperations: boolean;
  userName: string;
  lockStateMsg: string;
  money: number;
  chargeMode: number;
  u: string;
  phone: number;
  couponMoney: string;
  endTime: string;
  createTime: string;
  cabinetRoomId: number;
  rentTime: number;
  refundList: {
    id: number;
    handler: string;
    time: string;
    amount: string;
  }[];
}
